/* eslint-disable import/prefer-default-export */

export function isPreProduction() {
  return process.env.REACT_APP_BUILD_MODE === 'preproduction';
}

export function isProduction() {
  return process.env.REACT_APP_BUILD_MODE === 'production';
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function setCookie(cname, cvalue, exhours) {
  const d = new Date();
  d.setTime(d.getTime() + exhours * 60 * 60 * 1000);
  const expires = `expires=${d.toUTCString()}`;
  document.cookie = `${cname}=${cvalue};${expires};path=/`;
}

export function getCookie(cname) {
  const name = `${cname}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}

export function deleteCookie(cname) {
  document.cookie = `${cname}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
}

/*
 * element: html p element with the text
 * spaceAvailable: space the p element should have
 * array: empty array
 * */
export function animateText(element, spaceAvailable, array) {
  if (!element) {
    return;
  }
  const velocity = 100;
  const movement = spaceAvailable - element.scrollWidth;
  if (movement < 0) {
    array[0] = setTimeout(() => {
      element.style.textOverflow = 'unset';
      element.style.marginLeft = `${movement}px`;
      element.style.transition = `margin-left ${Math.abs(movement) / velocity}s linear`;
      array[1] = setTimeout(() => {
        element.style.transition = `margin-left ${Math.abs(movement) / velocity}s linear`;
        element.style.marginLeft = `0px`;
        setTimeout(() => {
          animateText(element, spaceAvailable, array);
        }, (Math.abs(movement) / velocity) * 1000);
      }, (Math.abs(movement) / velocity + 2) * 1000);
    }, 1000);
  }
}

/*
 * element: html p element with the text
 * array: array used in animateText function
 * */
export function inanimateText(element, array) {
  if (!element) {
    return;
  }
  for (let i = 0; i < 2; i++) {
    clearTimeout(array[i]);
    array[i] = undefined;
  }
  element.style.textOverflow = 'ellipsis';
  element.style.marginLeft = '0px';
  element.style.transition = 'none';
}

/* eslint-disable no-undef  */
export function getInstalledApps(callback = () => {}) {
  try {
    Hisense_getInstalledApps(function (err, json) {
      if (err) {
        console.log(`Error getting apps: ${err.name}(${err.message})`);
        callback(false);
      } else {
        console.log('NO ERROR');
        console.log(json);
        const allHisenseApps = JSON.parse(json).AppInfo;
        for (let i = 0; i < allHisenseApps.length; i++) {
          // console.log("Parsing app:", allHisenseApps[i]);
          const appid = allHisenseApps[i].Id || allHisenseApps[i].AppId;
          if (allHisenseApps[i].PreInstall === false && appid) {
            installedApps.push(appid);
          }
        }
        callback(true);
      }
    });
  } catch (e) {
    console.log(' _ _             \n' + '|_/ \\\\/\\/| ||\\/| \n' + '| \\_//\\/\\|_||  | \n' + '                 ');
    console.log('This is not a compatible device with the install function');
    callback(e);
  }
}

export function installApp(id, appName, logo, appUrl) {
  getInstalledApps(() => {
    if (installedApps.length < 100) {
      const logoN = logo; // .replace('Images_384x216_angled', 'Images_280x396_angled').replace('jpg', 'png');
      try {
        Hisense_installApp(id, appName, logoN, logoN, logoN, appUrl, 'store', function (err) {
          if (err) {
            console.log(`Error install app: ${err.name}(${err.message})`);
          } else {
            console.log('installed app success ! ');
            refreshAppsOnHisenseUI();
          }
        });
      } catch (e) {
        console.log(e);
      }
      setTimeout(() => {
        getInstalledApps();
      }, 2000);
    }
  });
}

export function uninstallApp(id) {
  try {
    Hisense_uninstallApp(id, function (err) {
      if (!err) {
        console.log(`Error uninstall app`);
      } else {
        console.log('uninstalled app success ! ');
        refreshAppsOnHisenseUI();
        getInstalledApps();
      }
    });
  } catch (e) {
    console.log(e);
    getInstalledApps();
  }
}

export function refreshAppsOnHisenseUI() {
  //refresh apps on UI.

  var cmdInfo = {};
  cmdInfo.type = 'APPMessage';
  cmdInfo.source = 'browser';
  cmdInfo.startAppType = 2;
  cmdInfo.param = {
    event: 'AllAppsUpdate',
    SubModuleName: 'AllApps',
    startFrom: 'vidaastore',
  };

  if (!!window.omi_platform) {
    omi_platform.sendPlatformMessage(JSON.stringify(cmdInfo));
  } else if (!!window.opera_omi) {
    opera_omi.sendPlatformMessage(JSON.stringify(cmdInfo));
  }
}

export function getBrand() {
  try {
    const brand = Hisense_GetBrand();
    switch (brand) {
      case 'nob':
      case 'noblex':
        return 'nob';
      default:
        return 'default';
    }
  } catch (e) {
    return 'default';
  }
}
